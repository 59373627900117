
import { computed, defineComponent, onMounted, ref, toRefs } from 'vue'
import { getMenu } from '@/api/index'
import { getInfo, removeRefreshToken, removeToken } from '@/api/cookie'
import { useRouter } from 'vue-router'
import { OSSURL } from '@/hooks/ali-oss'
import { useStore } from 'vuex'
import { GlobalDataProps } from '@/store/index'
export default defineComponent({
  components: {},
  setup() {
    const router = useRouter()
    const userInfo = getInfo()
    // console.log(userInfo, 'userInfo')
    const selectRouter = ({ ...obj }) => {
      sessionStorage.setItem('routerIndex', obj.key)
      console.log(obj.key, 123)
    }
    const routerList = ref([])
    const selectedKeys = ref<string[]>(['0'])
    const path = router.options.history.location.split('/')[1]
    console.log(path)
    const getKey = () => {
      console.log('导出')
    }
    // const get
    const getSelectedKeys = () => {
      const path = router.options.history.location.toUpperCase().split('/')[1]
      const _index = routerList.value.findIndex(
        (ele: any) => ele.href.toUpperCase() === path
      )
      console.log(router.options.history.location)
      console.log(_index.toString().split(''))
      if (_index >= 0) {
        return _index.toString().split('')
      } else {
        return ['0']
      }
      // return _index.toString().split('')
    }
    // const selectRouter = ({ ...obj }) => {
    //   selectedKeys.value = [obj.key]
    // }
    const store = useStore<GlobalDataProps>()
    onMounted(() => {
      const index_ = sessionStorage.getItem('routerIndex')
      if (index_) {
        selectedKeys.value = [index_]
      }
    })
    // 退出
    const goOut = () => {
      sessionStorage.clear()
      removeToken()
      removeRefreshToken()
      router.push({
        name: 'Login'
      })
    }
    return {
      routerList,
      selectedKeys,
      selectRouter,
      userInfo,
      goOut,
      OSSURL,
      store,
      router
    }
  }
})
