/**
 * 基础用法
 * 上传一种类型
 * <ali-oss
    ref="updateFile"
    :upObj="upObj"
    :oldList="list"
    @changeFile="changeFile">
    </ali-oss>

    上传多种大类型
    <newAlyUp
    ref="upload"
    :upObj="upObj"
    :oldList="list"
    :maxNum="maxNum"
    :filter="newUpObj.filter"
    :isMore="true"
    @changeFile="changeFile">
    </newAlyUp>

    newUpObj: {
      filter: '.ppt.pptx.pdf.doc.docx.xls.xlsx.mp4.mp3',
    }
 */
export const OSSURL = 'https://ind.oss.arseek.cn'
export const FILES_TYPE = [
  {
    type: 'w1',
    value: '.doc.docx'
  }, {
    type: 'w2',
    value: '.ppt.pptx'
  }, {
    type: 'w3',
    value: '.xls.xlsx'
  }, {
    type: 'w4',
    value: '.pdf'
  }, {
    type: 'p1',
    value: '.png.jpg'
  }, {
    type: 'm3',
    value: '.mp3'
  }, {
    type: 'm4',
    value: '.mp4'
  }, {
    type: '1',
    value: '.abw'
  }, {
    type: '2',
    value: '.aba'
  }, {
    type: '3',
    value: '.abi'
  }, {
    type: '4',
    value: '.abu'
  }
]
export const SEND_TYPE = {
  1: {
    lable: '图片',
    filter: '.png.jpg',
    bunket: 'image', // 必传
    maxNum: 8,
    size: 5,
    type: 'p1',
    isRealName: true // 是否重命名
  },
  2: {
    lable: '音频',
    filter: '.mp3',
    bunket: 'audio',
    maxNum: 5,
    size: 50,
    type: 'm3',
    isRealName: true
  },
  3: {
    lable: '视频',
    filter: '.mp4',
    bunket: 'video',
    maxNum: 5,
    size: 100,
    type: 'm4',
    isRealName: true
  },
  4: {
    lable: '文档',
    filter: '.pdf.doc.docx.xls.xlsx',
    bunket: 'wps',
    maxNum: 5,
    size: 10,
    type: 'w',
    isRealName: true
  },
  5: {
    lable: 'abw',
    filter: '.abw',
    bunket: 'ab',
    maxNum: 1,
    type: 'abw',
    isRealName: true
  },
  6: {
    lable: 'aba',
    filter: '.aba',
    bunket: 'ab',
    maxNum: 1,
    type: 'aba',
    isRealName: true
  },
  7: {
    lable: 'abi',
    filter: '.abi',
    bunket: 'ab',
    maxNum: 1,
    type: 'abi',
    isRealName: true
  },
  8: {
    lable: 'PPT',
    filter: '.ppt.pptx',
    bunket: 'ppt',
    maxNum: 5,
    size: 10,
    type: 'w2',
    isRealName: true
  },
  9: {
    lable: 'abu',
    filter: '.abu',
    bunket: 'ab',
    maxNum: 1,
    type: 'abu',
    isRealName: true
  }
}

export const SEND_TYPE_new = {
  1: {
    lable: '图片',
    filter: '.png.jpg',
    bunket: 'image', // 必传
    maxNum: 20,
    size: 5,
    type: 'p1',
    isRealName: true // 是否重命名
  },
  2: {
    lable: '音频',
    filter: '.mp3',
    bunket: 'audio',
    maxNum: 20,
    size: 50,
    type: 'm3',
    isRealName: true
  },
  3: {
    lable: '视频',
    filter: '.mp4',
    bunket: 'video',
    maxNum: 20,
    size: 100,
    type: 'm4',
    isRealName: true
  },
  4: {
    lable: '文档',
    filter: '.pdf.doc.docx.xls.xlsx',
    bunket: 'wps',
    maxNum: 20,
    size: 10,
    type: 'w',
    isRealName: true
  },
  5: {
    lable: 'abw',
    filter: '.abw',
    bunket: 'ab',
    maxNum: 20,
    type: 'abw',
    isRealName: true
  },
  6: {
    lable: 'aba',
    filter: '.aba',
    bunket: 'ab',
    maxNum: 20,
    type: 'aba',
    isRealName: true
  },
  7: {
    lable: 'abi',
    filter: '.abi',
    bunket: 'ab',
    maxNum: 20,
    type: 'abi',
    isRealName: true
  },
  8: {
    lable: 'PPT',
    filter: '.ppt.pptx',
    bunket: 'ppt',
    maxNum: 20,
    size: 10,
    type: 'w2',
    isRealName: true
  },
  9: {
    lable: 'abu',
    filter: 'abu',
    bunket: 'ab',
    maxNum: 20,
    type: 'abu',
    isRealName: true
  }
}

export const OSS_ICON = {
  m4: {
    url: require('@/assets/images/icon_file_mp4.png')
  },
  m3: {
    url: require('@/assets/images/icon_audio_max.png')
  },
  w1: {
    url: require('@/assets/images/icon_doc.png')
  },
  w2: {
    url: require('@/assets/images/icon_ppt.png')
  },
  w3: {
    url: require('@/assets/images/icon_xls.png')
  },
  w4: {
    url: require('@/assets/images/icon_ppt.png')
  }
}

// 项目附件
export const PROJECT_TYPE_new = {
  1: {
    lable: '图片',
    filter: '.png.jpg',
    bunket: 'image', // 必传
    maxNum: 20,
    size: 5,
    type: '1',
    isRealName: true // 是否重命名
  },
  2: {
    lable: '音频',
    filter: '.mp3',
    bunket: 'audio',
    maxNum: 20,
    size: 50,
    type: '3',
    isRealName: true
  },
  3: {
    lable: '视频',
    filter: '.mp4',
    bunket: 'video',
    maxNum: 20,
    size: 100,
    type: '2',
    isRealName: true
  },
  4: {
    lable: 'word',
    filter: '.doc.docx',
    bunket: 'wps',
    maxNum: 20,
    size: 10,
    type: '5',
    isRealName: true
  }
}

export const PROJECT_OSS_ICON = {
  2: {
    url: require('@/assets/images/icon_file_mp4.png')
  },
  3: {
    url: require('@/assets/images/icon_audio_max.png')
  },
  5: {
    url: require('@/assets/images/icon_doc.png')
  }
}
